<template>
  <div>
    <GmapMap ref="mapRef" :center="cordinatesData" :zoom="17" style="height: 360px">
      <GmapMarker :position="cordinatesData" :clickable="true"></GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    cordinates: {
      type: Object
    }
  },
  watch: {
    cordinates: {
      handler: "setData",
      immediate: true
    }
  },
  data() {
    return {
      cordinatesData: { lat: -1.219719, lng: 36.889224 }
    };
  },
  methods: {
    setData(val) {
      this.cordinatesData = val;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>