<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Name"
              filled
              v-model="outlet.CardName"
              dense
              outlined
              readonly
              :error="falseValue"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Region" filled v-model="region" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Channel" filled v-model="outlet.Channel" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Tier" filled v-model="outlet.tier" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field label="Longitude" filled v-model="outlet.Latitude" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field label="Latitude" filled v-model="outlet.Longitude" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Address" filled v-model="region" dense outlined readonly></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8">
        <v-row>
          <v-col cols="3">
            <v-card>
              <v-card-text>
                Total Calls
                <v-chip class="ma-2" small>{{outlet.TotalCalls}}</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card>
              <v-card-text>
                Complete Calls
                <v-chip class="ma-2" small>{{outlet.TotalCalls}}</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card tile>
              <v-card-text>
                Total Sales
                <v-chip class="ma-2" small>{{outlet.TotalBalance}}</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card>
              <v-card-text>
                Contacts<br>
                <v-chip class="ma-2" small>0</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- map -->
        <map-section :cordinates="cordinates"></map-section>
        <!-- map -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapSection from "../_components/map.vue";
export default {
  components: {
    MapSection
  },
  data() {
    return {
      falseValue: false,
      name: "Peter waithaka",
      region: "Nairobi",
      outlet: {},
      cordinates: {}
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/v1/outlet/${val}`)
        .then(res => {
          const cData = {
            lat: parseFloat(res.Latitude),
            lng: parseFloat(res.Longitude)
          };
          console.log(cData, 'ct');
          self.cordinates = cData;
          self.outlet = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {

  }
};
</script>

<style>
</style>